import { Outlet, useNavigate } from "react-router-dom";
import useStyles from "./style";
import Header from "./Header/Header";
import ToolBar from "./ToolBar/ToolBar";
import { useEffect } from "react";

const MainPage = ({ button, setButton, setData }: any) => {
  const classes = useStyles();
  let navigate = useNavigate();
  useEffect(() => {
    const url = new URL(window.location.href);
    const authorizationCode = url.searchParams.get("code");
    if (authorizationCode) {
      const id = process.env.REACT_APP_CLIENT_ID;
      console.log(id);
      
      const secret = process.env.REACT_APP_SECRET;
      const authHeader = "Basic " + btoa(id + ":" + secret);
      const apiUrl = `https://${process.env.REACT_APP_API_BASE_URL}`
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: authHeader,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          code: authorizationCode,
          redirect_uri: `http://${process.env.REACT_APP_REDIRECT_URI}/oaut2/auth-service/authorized`
        }),
        credentials: "include" as RequestCredentials,
      };
      fetch(
        `${apiUrl}/oauth2/token?grant_type=authorization_code`,
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              "Failed to exchange authorization code for access token"
            );
          }
        })
        .then((data) => {
          localStorage.setItem("accessToken", data.access_token);
          setData(data);
          navigate("/oaut2/auth-service/authorized/home");
        })
        .catch((error) => {
          localStorage.removeItem("accessToken");
          console.error("Token exchange error:", error);
        });
    } else {
      return;
    }
  }, [navigate, setData]);

  useEffect(() => {
    const isAuthenticated = () => {
      if (localStorage.getItem("accessToken") === null) {
        localStorage.removeItem("accessToken");
        navigate("/");
      } else {
        return true;
      }
    };
    isAuthenticated();
  }, [navigate]);

  return (
    <div
      onClick={() => {
        setButton(false);
      }}
      className={classes.root}
    >
      <header>
        <Header button={button} setButton={setButton} />
      </header>
      <aside>
        <ToolBar />
      </aside>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default MainPage;