import {IconHomeOutline, IconList, IconFileUpload} from '@picsart/icons'
import useStyles from "./style";
import { NavLink } from 'react-router-dom';

const ToolBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.toolbar}>
      <div className={classes.link}>
        <NavLink to="/oaut2/auth-service/authorized/home">
          <IconHomeOutline />
          <span>Home</span>
        </NavLink>
        <NavLink to="/oaut2/auth-service/authorized/clients">
          <IconList />
          <span>API Clients</span>
        </NavLink>
        <NavLink to="/oaut2/auth-service/authorized/fileupload">
          <IconFileUpload />
          <span>File Upload</span>
        </NavLink>
      </div>
    </div>
  )
}

export default ToolBar