import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  tableContainer: {
    width: '95%',
    marginTop: 100
  }
});

export default useStyles;
