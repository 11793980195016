import { Avatar, AvatarElementTypes } from "@picsart/avatar";
import { useRef, useState } from "react";
import Button from "@picsart/button/Button";
import { ButtonVariants } from "@picsart/button";
import useStyles from "./style";
import { NavLink } from "react-router-dom";
import { IconNewWindow } from "@picsart/icons";

const user = {
  tagName: "AT",
  name: "Arthur Tadevosyan",
  mail: "arthur.tadevosyan@picsart.academy",
};

const AvatarComponent = ({ button, setButton }: any) => {
  const root = useRef(null);
  const classes = useStyles();
  const [overlayIsVisible, setOverlayIsVisible] = useState<boolean>(false);

  return (
    <div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setButton(!button);
        }}
        ref={root}
        variant={ButtonVariants.Text}
      >
        <Avatar tag={AvatarElementTypes.Container} size={35} initials="AT" />
      </Button>
      {button ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={classes.container}
        >
          <div className={classes.account}>
            <h4>Account</h4>
            <form className={classes.form}>
              <Avatar
                tag={AvatarElementTypes.Button}
                size={35}
                initials={user.tagName}
              />
              <div>
                <h3>{user.name}</h3>
                <p>{user.mail}</p>
              </div>
            </form>
            <div className={classes.manage}>
              <a
                href="https://id.atlassian.com/manage-profile/profile-and-visibility"
                target="_blank"
                rel="noreferrer"
              >
                <p>Manage Account</p> <IconNewWindow />
              </a>
            </div>
          </div>
          <div className={classes.logOut}>
            <button onClick={() => setOverlayIsVisible(true)}>Log Out</button>
            {overlayIsVisible && (
              <div>
                <div className={classes.overlayBackground}>
                  <div className={classes.overlayContainer}>
                    <p>Are you sure you want to log out?</p>
                    <div className={classes.loger}>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOverlayIsVisible(false);
                          setButton(!button);
                        }}
                      >
                        Cancel
                      </p>
                      <NavLink onClick={() => localStorage.removeItem("accessToken")}  to="/">Log out</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AvatarComponent;
