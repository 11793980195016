import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    header: {
        display: 'flex',
        position:"relative",
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '18px 20px',
        borderBottom: "2px solid rgba(160, 160, 160, 0.3)",
    }
});

export default useStyles;