import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    position: "fixed",
    top: 70,
    right: 8,
    display: "flex",
    borderRadius: 2,
    flexDirection: "column",
    boxShadow: "0 2px 8px 0 rgba(0,0,0, 0.3)",
    backgroundColor: "white",
    width: 320,
    color: "#6f6f6f",
    fontFamily:
      '--apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
    fontSize: 14,
    fontWeight: 400,
    zIndex: "1",
  },
  account: {
    height: "36%",
    borderBottom: "2px solid #dfdfdf",
    fontWeight: "bold",
    "& h4": {
      padding: 12,
      paddingBottom: 6,
    },
  },
  manage: {
    width: "100%",
    paddingTop: 10,
    "& a": {
      display: "flex",
      alignItems: "centre",
      justifyContent: "space-between",
      padding: "12px",
      color: "#6f6f6f",
      fontSize: 12,
      "& svg": {
        fill: "black",
        padding: 2,
      },
    },
    "& p": {
      paddingTop: 4,
    },
  },
  form: {
    display: "flex",
    gap: 10,
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "normal",
    paddingTop: "10px",
  },
  logOut: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    "& button": {
      border: "none",
      backgroundColor: "white",
      padding: "14px",
    },
    "& button:hover": {
      backgroundColor: "rgb(238,238,238)",
    },
  },
  loger: {
    display: "flex",
    gap: 90,
    alignItems: "center",
    justifyContent: "center",
    borderTop: "2px solid #dfdfdf",
    marginTop: 28,
    paddingTop: 14,
  },
  overlayBackground: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "sans-serif",
    color: "#333",
    fontSize: 16,
    "& a": {
      color: "red",
    },
  },
  overlayContainer: {
    backgroundColor: "white",
    padding: "52px 36px",
    borderRadius: "5px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
    width: "300px",
    height: "170px",
    textAlign: "center",
  },
});

export default useStyles;
