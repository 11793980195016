import { Loader } from "@picsart/loader";
import useStyles from "./style";
import IconPicsartCircleColored from "@picsart/icons/IconPicsartCircleColored";
import { useEffect, useState } from "react";

const LoginPage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className={classes.container}>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classes.loginCantainer}>
          <h1>Welcome PlatformTools!</h1>
          <form className={classes.form}>
            <p>Sign in to continue</p>
            <a
              className={classes.link}
              href={`${process.env.REACT_APP_API_SSO}?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=user-global%20api-management.admin&redirect_uri=http://${process.env.REACT_APP_REDIRECT_URI}/oaut2/auth-service/authorized`}
            >
              <IconPicsartCircleColored /> Sign in with Picsart
            </a>
          </form>
        </div>
      )}
    </div>
  );
};

export default LoginPage;