import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    display: "grid",
    gridTemplateAreas: '"header" "content"',
    backgroundColor: 'white',
    gridTemplateRows: "84px 1fr",
    "& header": {
      gridArea: "header",
      position: "fixed",
      top: "0",
      width: "100%",
      height: 84,
      backgroundColor: 'white',
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
      zIndex: 10
    },
    "& section": {
      gridArea: "content",
      height: '100vh',
      width: '100%'
    },
  },
});

export default useStyles;
