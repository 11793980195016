import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  FormInstance,
  Input,
  Popconfirm,
  Table,
  Typography,
  Space,
  Button,
} from "antd";
import type { TableProps } from "antd/es/table";
import type {
  FilterConfirmProps,
  TableRowSelection,
} from "antd/es/table/interface";
import useStyles from "./style";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import type { InputRef } from "antd";
import type { ColumnType } from "antd/es/table";
import { NavLink } from "react-router-dom";

interface DataType {
  key: string;
  clientName: string;
  clientID: number;
  clientIdIssuedAt: string;
  scopes: string;
  description: string;
}

const originData: DataType[] = [];
for (let i = 1; i <= 100; i++) {
  originData.push({
    key: i.toString(),
    clientName: "John Brown",
    clientID: Number(`${i}2`),
    clientIdIssuedAt: "John Brown",
    scopes: `New York No. ${i} Lake Park`,
    description: `My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park.`,
  });
}

type DataIndex = keyof DataType;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: DataType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const APIList = ({tokenData}: any) => {
  const accessToken = `Bearer ${tokenData.access_token}`;
  useEffect(() => {
    console.log(accessToken);
    fetch('https://api-platform-stage.picsart.tools/client', { 
      headers: {
        Authorization : accessToken
      },
      credentials: "include" as RequestCredentials,
    })
    .then((resp) => {
      console.log(resp.json());
    }).catch((error) => {
      console.log(error);
    })
  }, [accessToken])


  const [form] = Form.useForm();
  const [rowSelection, setRowSelection] = useState<
    TableRowSelection<DataType> | undefined
  >({});
  // const [hasData, setHasData] = useState(true);
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState(originData);
  const isEditing = (record: DataType) => record.key === editingKey;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const classes = useStyles();

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleRowSelectionChange = (enable: boolean) => {
    setRowSelection(enable ? {} : undefined);
  };

  // const edit = (record: Partial<DataType> & { key: React.Key }) => {
  //   form.setFieldsValue({ name: "", id: "", scopes: "", ...record });
  //   setEditingKey(record.key);
  // };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as DataType;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = (key: React.Key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };

  const columns = [
    {
      title: "clientID",
      dataIndex: "clientID",
      key: "clientID",
      width: "25%",
      editable: true,
      ...getColumnSearchProps("clientID"),
    },
    {
      title: "clientName",
      dataIndex: "clientName",
      key: "clientName",
      width: "15%",
      editable: true,
      ...getColumnSearchProps("clientName"),
    },
    {
      width: "20%",
      title: "clientIdIssuedAt",
      dataIndex: "clientIdIssuedAt",
      key: "clientIdIssuedAt",
      editable: true,
      ...getColumnSearchProps("clientIdIssuedAt"),
    },
    {
      title: "Scopes",
      dataIndex: "scopes",
      key: "scopes",
      width: "15%",
      editable: true,
      ...getColumnSearchProps("scopes"),
    },
    {
      title: "Action",
      dataIndex: "operation",
      editable: false,
      render: (_: any, record: DataType) => {
        const editable = isEditing(record);
        return (
          <>
            <div>
              {editable ? (
                <span>
                  <Typography.Link
                    onClick={() => save(record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <button>Cancel</button>
                  </Popconfirm>
                </span>
              ) : (
                // <Typography.Link
                //   disabled={editingKey !== ""}
                //   onClick={() => edit(record)}
                // >
                //   Edit
                // </Typography.Link>
                <NavLink to="/oaut2/auth-service/authorized/edit-client"><span>Edit</span></NavLink>
              )}
            </div>
            <div>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <button>Delete</button>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const tableProps: TableProps<DataType> = {
    bordered: true,
    expandable: {
      expandedRowRender: (record: DataType) => <p>{record.description}</p>,
    },
    footer: () => "",
    rowSelection,
    size: "small",
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        inputtype: col.dataIndex === "text" ? "true" : "false",
        dataIndex: col.dataIndex,
        title: col.title as string,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className={classes.tableContainer}>
      <Button onClick={() => handleRowSelectionChange(!!rowSelection)}>
        {rowSelection ? "Disable" : "Enable"} Row Selection
      </Button>
      <Form form={form} component={false}>
        <Table
          {...tableProps}
          components={{
            body: {
              cell: EditableCell,
              row: EditableRow,
            },
          }}
          columns={mergedColumns}
          dataSource={data}
          bordered
          rowClassName="editable-row"
        />
      </Form>
    </div>
  );
};

export default APIList;