import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  tableContainer: {
    width: "80%",
    margin: "200px auto",
  },
});

export default useStyles;
