import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  toolbar: {
    position: "fixed",
    borderRight: "1px solid rgba(190, 190, 190, 0.5)",
    borderTop: "2px solid rgba(160, 160, 160, 0.3)",
    height: "100vh",
    backgroundColor: "white",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.4)",
  },
  link: {
    display: "flex",
    alignItems: "centre",
    justifyContent: "space-around",
    flexDirection: "column",
    width: "235px",
    padding: "10px 12px",
    "& a.active": {
      backgroundColor: "#e6f4ff",
      color: "#1677ff",
    },
    "& a:not(:active):hover": {
      backgroundColor: "rgb(248,248,248)",
    },
    "& a": {
      display: "flex",
      alignItems: "centre",
      justifyContent: "centre",
      gap: "10px",
      padding: "10px",
      color: "black",
      "& svg": {
        fill: "black",
      },
      "& span": {
        paddingTop: "4px",
      },
    },
  },
});

export default useStyles;
