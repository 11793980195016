import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    height: "100vh",
    display: "grid",
    gridTemplateAreas: '"header header " "sidebar content"',
    gridTemplateColumns: "1fr 7fr",
    gridTemplateRows: "84px 1fr",
    "& header": {
      gridArea: "header",
      position: "fixed",
      top: "0",
      width: "100%",
      height: 84,
      backgroundColor: 'white',
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
      zIndex: 10
    },
    "& aside": {
      gridArea: "sidebar",
      height: "100vh",
      position: "sticky",
    },
    "& main": {
      gridArea: "content",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  
});

export default useStyles;
