import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import { Loader } from "@picsart/loader";
import useStyles from "./style";

interface DataType {
  key: string;
  clientName: string;
  clientID: number;
  clientIdIssuedAt: string;
  scopes: string;
  description: string;
}

const originData: DataType[] = [
  {
    key: "1",
    clientName: "Edward",
    clientID: 32,
    clientIdIssuedAt: "Edward",
    scopes: "London Park no.",
    description: "London Park no.",
  },
];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: DataType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const APITable = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const isEditing = (record: DataType) => record.key === editingKey;

  const edit = (record: Partial<DataType> & { key: React.Key }) => {
    form.setFieldsValue({ clientName: "", clienID: "", scopes: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as DataType;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "clientID",
      dataIndex: "clientID",
      key: "clientID",
      width: "10%",
      editable: true,
    },
    {
      title: "clientName",
      dataIndex: "clientName",
      key: "clientName",
      width: "20%",
      editable: true,
    },
    {
      width: "20%",
      title: "clientIdIssuedAt",
      dataIndex: "clientIdIssuedAt",
      key: "clientIdIssuedAt",
      editable: true,
    },
    {
      title: "Scopes",
      dataIndex: "scopes",
      key: "scopes",
      width: "25%",
      editable: true,
    },
    {
      title: "Action",
      width: "10%",
      dataIndex: "action",
      render: (_: any, record: DataType) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <button>Cancel</button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classes.tableContainer}>
          <Form form={form} component={false}>
            <Table
              size="large"
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              pagination={false}
              bordered
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
            />
          </Form>
        </div>
      )}
    </>
  );
};

export default APITable;
