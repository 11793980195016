import Layout from "@picsart/layout/Layout";
import Header from "../Header/Header";
import useStyles from "./style";
import APITable from "../Content/APIList/APITable";

const Clients = ({button, setButton}: any) => {
  const classes = useStyles();
  return (
    <div>
      <Layout
        className={classes.root}
        header={{
          component: (
            <header>
              <Header button={button} setButton={setButton}/>
            </header>
          ),
        }}
        main={{
          component: <section><APITable/></section>,
        }}
      />
    </div>
  );
};

export default Clients;
