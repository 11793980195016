import { createUseStyles } from "react-jss";
import {
  convertThemeToCSS,
  ThemeLightValues,
  ThemeDarkValues,
} from "@picsart/cascade/colors";

import {
  convertEasingToCSS,
  convertRadiusToCSS,
  convertSpacingsToCSS,
  convertTimingsToCSS,
  convertTypographyToCSS,
  EasingValues,
  RadiusValues,
  resetters,
  SpacingsValues,
  tabFocusStyles,
  TimingValues,
  TypographyValues,
} from "@picsart/cascade";

const useStyles = createUseStyles(
  {
    // @ts-ignore
    "@global": {
      ...resetters,
      ":root": {        
        ...tabFocusStyles,
        ...convertSpacingsToCSS(SpacingsValues),
        ...convertTimingsToCSS(TimingValues),
        ...convertRadiusToCSS(RadiusValues),
        ...convertEasingToCSS(EasingValues),
        ...convertTypographyToCSS(TypographyValues),
        "&.light": {
          ...convertThemeToCSS(ThemeLightValues),
        },
        "&.dark": {
          ...convertThemeToCSS(ThemeDarkValues),
        },
      },
    },
    root: {
      margin: '0 auto',
    },
  },
  {
    name: "gen-ai",
  }
);

export default useStyles;
