import AvatarComponent from "./AvatarComponent/AvatarComponent";
import useStyles from "./style";
import { LogoTypes, Logo } from "@picsart/logo";
import IconCheckboxOn from '@picsart/icons/IconCheckboxOn';
const Header = ({ button, setButton }:any) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Logo type={LogoTypes.Default} />
      <IconCheckboxOn />
      <AvatarComponent button={button} setButton={setButton} />
    </div>
  );
};

export default Header;
