import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  loginCantainer: {
    height: 420,
    width: 550,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 2px 8px 0 rgba(0,0,0,1)",
    borderRadius: 12,
    "& h1": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "80px 0px",
      color: "#df4fae",
    },
  },
  form: {
    // paddingTop: "80px",
    padding: "20px 74px",
    "& p": {
      color: "gray",
      padding: "14px 126px",
    },
  },
  link: {
    display: "flex",
    justifyContent: "normal",
    gap: "10px",
    border: "1px solid #d3d3d3",
    padding: "10px",
    fontSize: "20px",
    borderRadius: "12px",
  },
});

export default useStyles;
