import { Route, Routes } from "react-router-dom";
import useStyles from "./useStyles";
import MainPage from "./components/MainPage/MainPage";
import LoginPage from "./components/LoginPage";
import Home from "./components/MainPage/Content/Home/Home";
import APIList from "./components/MainPage/Content/APIList/APIList";
import FileUpload from "./components/MainPage/Content/FileUpload/FileUpload";
import Clients from "./components/MainPage/Clients/Clients";
import { useState } from "react";

const App = () => {
  const [button, setButton] = useState<boolean>(false);
  const [data, setData] = useState([]);
  useStyles();

  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/oaut2/auth-service/authorized"
          element={
            <MainPage button={button} setButton={setButton} setData={setData} />
          }
        >
          <Route path="home" element={<Home />} />
          <Route path="clients" element={<APIList tokenData={data} />} />
          <Route path="fileupload" element={<FileUpload />} />
        </Route>
        <Route
          path="/oaut2/auth-service/authorized/edit-client"
          element={<Clients button={button} setButton={setButton} />}
        />
      </Routes>
    </>
  );
};

export default App;
